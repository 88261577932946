import React from "react";
import "./../styles/global.scss";
import { Switch, Route, Router, Redirect } from "./../util/router";
import NavbarCustom from "./../components/NavbarCustom";
// import IndexPage from "./index";
// import AboutPage from "./about";
// import FaqPage from "./faq";
// import ContactPage from "./contact";
// import PricingPage from "./pricing";
// import DashboardPage from "./dashboard";
import DealerOrgsPage from "./dealerOrgs";
import DealerOrgPage from "./dealerOrg";
import UsersPage from "./users";
import AuthPage from "./auth";
// import SettingsPage from "./settings";
// import LegalPage from "./legal";
import NotFoundPage from "./404";
import Footer from "./../components/Footer";
import { AuthProvider } from "./../util/auth";
import { QueryClientProvider } from "./../util/db";

const logo = "/img/logo-navbar.svg";

function App(props) {
  return (
    <QueryClientProvider>
      <AuthProvider>
        <Router>
          <>
            <NavbarCustom
              bg="white"
              variant="light"
              expand="md"
              logo={logo}
            />

            <Switch>
              {/*<Route exact path="/" component={IndexPage} />*/}
              {/*<Route exact path="/about" component={AboutPage} />*/}
              {/*<Route exact path="/faq" component={FaqPage} />*/}
              {/*<Route exact path="/contact" component={ContactPage} />*/}
              {/*<Route exact path="/pricing" component={PricingPage} />*/}
              {/*<Route exact path="/dashboard" component={DashboardPage} />*/}
              {/*<Route exact path="/settings/:section" component={SettingsPage} />*/}
              {/*<Route exact path="/legal/:section" component={LegalPage} />*/}

              <Route exact path="/">
                <Redirect to="/auth/signin" />
              </Route>
              <Route exact path="/auth/:type" component={AuthPage} />
              <Route exact path="/dealerOrgs" component={DealerOrgsPage} />
              <Route exact path="/dealerOrgs/:dealerOrgId" component={DealerOrgPage} />
              <Route exact path="/users" component={UsersPage} />
              <Route component={NotFoundPage} />
            </Switch>

            <Footer
              bg="light"
              textColor="dark"
              size="sm"
              bgImage=""
              bgImageOpacity={1}
              copyright={`© ${new Date().getFullYear()} Atero`}
              logo={logo}
            />
          </>
        </Router>
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default App;
