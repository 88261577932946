import { configureStore } from '@reduxjs/toolkit';
import {
	usersApiSlice,
	dealerOrgApiSlice
} from '../api/client';

export default configureStore({
	reducer: {
		[usersApiSlice.reducerPath]: usersApiSlice.reducer,
		[dealerOrgApiSlice.reducerPath]: dealerOrgApiSlice.reducer,
	},
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware().concat(
			usersApiSlice.middleware
		).concat(
			dealerOrgApiSlice.middleware
		)
});
