import React, { useState } from "react";
import debounce from "lodash/debounce";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

function SearchInput(props) {
	const { setSearch, placeholder, defaultValue = '' } = props;
	const [proxySearch, setProxySearch] = useState(defaultValue);
	const debounceSetSearch = debounce(setSearch, 200);

	return (
		<InputGroup>
			<Form.Control
        placeholder={placeholder || "Search"}
        aria-label="Search"
        value={proxySearch}
        onChange={e => {
        	setProxySearch(e.target.value);
        	debounceSetSearch(e.target.value);
        }}
      />
		</InputGroup>
	);
};

export default SearchInput;
