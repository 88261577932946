import axios from "axios";
import apiUri from '../util/apiUri';
import { keepUnusedDataFor } from './client/constants';

async function getDealerOrgs({search, sort, sortDir}) {
	try {
		const dealerOrgs = await axios.get(apiUri('dealerOrg'), {
  		params: {
  			search: search.trim(),
  			sort: sort,
  			sortDir: sortDir,
  		}
  	}).then(res => res.data);
  	return { data: dealerOrgs };
	} catch(error) {
		return { error: error?.data?.message || error.message || 'An unknown error occured' };
	}
};

async function getDealerOrg(dealerOrgId) {
	try {
		const dealerOrg = await axios.get(
			apiUri(`dealerOrg/${dealerOrgId}`)
		).then(res => res.data);
		return { data: dealerOrg };
	} catch(error) {
		return { error: error?.data?.message || error.message || 'An unknown error occured' };
	}
};

async function getDealerOrgDealers({dealerOrgId, search, sort, sortDir}) {
	try {
		const dealers = await axios.get(
			apiUri(`dealerOrg/${dealerOrgId}/dealers`),
			{
				params: {
					search: search.trim(),
					sort: sort,
					sortDir: sortDir,
				}
			}
		).then(res => res.data);
		return { data: dealers };
	} catch(error) {
		return { error: error?.data?.message || error.message || 'An unknown error occured' };
	}
};

async function getDealerOrgBuyers({dealerOrgId, search, sort, sortDir}) {
	try {
		const buyers = await axios.get(
			apiUri(`dealerOrg/${dealerOrgId}/buyers`),
			{
				params: {
					search: search.trim(),
					sort: sort,
					sortDir: sortDir,
				}
			}
		).then(res => res.data);
		return { data: buyers };
	} catch(error) {
		return { error: error?.data?.message || error.message || 'An unknown error occured' };
	}
};

async function getDealerOrgBuyerOrgs({dealerOrgId, search, sort, sortDir}) {
	try {
		const buyers = await axios.get(
			apiUri(`dealerOrg/${dealerOrgId}/buyerOrgs`),
			{
				params: {
					search: search.trim(),
					sort: sort,
					sortDir: sortDir,
				}
			}
		).then(res => res.data);
		return { data: buyers };
	} catch(error) {
		return { error: error?.data?.message || error.message || 'An unknown error occured' };
	}
};

async function getDealerOrgManufacturers({dealerOrgId, search, sort, sortDir}) {
	try {
		const manufacturers = await axios.get(
			apiUri(`dealerOrg/${dealerOrgId}/manufacturers`),
			{
				params: {
					search: search.trim(),
					sort: sort,
					sortDir: sortDir,
				}
			}
		).then(res => res.data);
		return { data: manufacturers };
	} catch(error) {
		return { error: error?.data?.message || error.message || 'An unknown error occured' };
	}
};

async function getDealerOrgOrderSummary(dealerOrgId) {
	try {
		const orderSummary = await axios.get(
			apiUri(`dealerOrg/${dealerOrgId}/orderSummary`)
		).then(res => res.data).catch(err =>{
			console.error(err);
			throw err;
		});
		return { data: orderSummary };
	} catch(error) {
		return { error: error?.data?.message || error.message || 'An unknown error occured' };
	}
};

export const handlers = (builder) => ({
	getDealerOrgs: builder.query({
		queryFn: getDealerOrgs,
		keepUnusedDataFor: keepUnusedDataFor,
	}),
	getDealerOrg: builder.query({
		queryFn: getDealerOrg,
		keepUnusedDataFor: keepUnusedDataFor,
	}),
	getDealerOrgDealers: builder.query({
		queryFn: getDealerOrgDealers,
		keepUnusedDataFor: keepUnusedDataFor,
	}),
	getDealerOrgBuyers: builder.query({
		queryFn: getDealerOrgBuyers,
		keepUnusedDataFor: keepUnusedDataFor,
	}),
	getDealerOrgBuyerOrgs: builder.query({
		queryFn: getDealerOrgBuyerOrgs,
		keepUnusedDataFor: keepUnusedDataFor,
	}),
	getDealerOrgManufacturers: builder.query({
		queryFn: getDealerOrgManufacturers,
		keepUnusedDataFor: keepUnusedDataFor,
	}),
	getDealerOrgOrderSummary: builder.query({
		queryFn: getDealerOrgOrderSummary,
		keepUnusedDataFor: keepUnusedDataFor,
	}),
});

export default handlers;
