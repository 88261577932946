import React from 'react';
import classnames from 'classnames';
import Flex from '../Flex';
import './Toggle.scss';

const Option = ({ active, setActive, op, isOpActive }) => {
	const isActive = typeof isOpActive === 'function' ? isOpActive(active, op)
		: Array.isArray(active) ? active.includes(op)
			: typeof active === 'function' ? active(op)
				: active === op;

	return (
		<div
			className={classnames('Option', { active: isActive })}
			onClick={() => setActive(op)}
		>
			{op}
		</div>
	);
}

function Toggle(props) {
	const { active, setActive, ops = [], isOpActive, className = "", label, labelClassName = "" } = props;

	return (
		<>
			{label && (
				<span className={labelClassName}>{label}</span>
			)}
			<Flex className={`Toggle ${className}`.trim()}>
				{ops.map((op, idx) => (
					<Option
						key={idx}
						idx={idx}
						active={active}
						setActive={setActive}
						op={op}
						isOpActive={isOpActive}
					/>
				))}
				{props.children}
			</Flex>
		</>
	);
}

export default Toggle;
