import React from "react";
import Meta from "./../components/Meta";
import DealerOrg from "./../components/DealerOrg";
import { requireAuth } from "./../util/auth";

function DealerOrgPage(props) {
  return (
    <>
      <Meta title="Dealer Org" />
      <DealerOrg
      	bg="light"
      	textColor="dark"
      	size="sm"
      	title="Dealer Orgs"
      	subtitle=""
      />
    </>
  );
}

export default requireAuth(DealerOrgPage);
