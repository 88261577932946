import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faCircleXmark, faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import Flex from '../Flex';

import './Popup.scss';

const isFunction = func => typeof func === 'function';

function Popup(props, ref) {
	const [open, setOpen] = useState(!!props.open);
	const [isOpening, setIsOpening] = useState(false);
	const [isOpened, setIsOpened] = useState(false);
	const animationTime = 300; //ms

	const { onOpen, afterOpen, onClose, afterClose, title, loading, preventClose } = props;

	useEffect(() => {
		if (isFunction(onOpen) && !!open) {
			onOpen();
		}

		if (isFunction(onClose) && !open) {
			onClose();
		}

		setTimeout(() => {
			setIsOpening(open);

			setTimeout(() => {
				setIsOpened(open);

				if (isFunction(afterOpen) && !!open) {
					afterOpen();
				}

				if (isFunction(afterClose) && !open) {
					afterClose();
				}
			}, animationTime);
		}, 0);

		document.querySelector('body').classList.toggle('noscroll', !!open);
	}, [open, onOpen, afterOpen, onClose, afterClose]);

	useEffect(() => {
		function handleEscKeypress(e) {
			if (e.keyCode === 27 && !preventClose) {
				setOpen(false);
			}
		};

		document.addEventListener('keydown', handleEscKeypress);

		return () => document.removeEventListener('keydown', handleEscKeypress);
	}, []);

	const className = [
		'Popup',
		...open ? ['open'] : [],
		...isOpening ? ['isOpening'] : [],
		...isOpened ? ['isOpened'] : [],
		...props.className ? [props.className] : [],
	].join(' ');

	useImperativeHandle(ref, () => ({
		closePopup: () => setOpen(false),
	}));

	return (
		<div
			className={className}
			onClick={(e) => !preventClose && e.currentTarget === e.target && setOpen(!open)}
		>
			<div
				className={`popup-container ${props.containerClass || ''}`}
				_size={props.size}
			>
				<Flex
					justify="between"
					align="center"
					className={`back-btn ${props.backBtnClass || ''}`}
				>
					<span
						className="d-block d-lg-none text-info pointer mobile text-nowrap"
						onClick={() => !preventClose && setOpen(false)}
					>
						<FontAwesomeIcon icon={faAngleLeft} className="f-rem-0.9" /> Back
					</span>

					{(loading || title) && (
						<Flex align="center" className="px-2.5 text-truncate">
							{loading && (
								<FontAwesomeIcon
									icon={faSpinnerThird}
									spin={true}
									className="text-secondary f-rem-0.9"
								/>
							)}
							{title && (
								<span
									className={classnames('text-truncate', {
										'ml-1.5': loading,
									})}
								>
									{title}
								</span>
							)}
						</Flex>
					)}
					<span
						className="d-none d-lg-block pointer desktop"
						onClick={() => !preventClose && setOpen(false)}
					>
						<FontAwesomeIcon icon={faCircleXmark} className="f-rem-0.9" />
					</span>
				</Flex>

				{props.children}
			</div>
		</div>
	);
}

export default forwardRef(Popup);
