import React, { useState, useCallback } from "react";
import debounce from "lodash/debounce";
import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Section from "../Section";
import SectionHeader from "../SectionHeader";
import SortIcon from "../SortIcon";
import Spinner from "../Spinner";
import { useHistory } from '../../util/router';
import { currencyFormat, commaFormat } from '../../util/numbers';
import { useGetDealerOrgsQuery } from '../../api/client';

const Search = ({setSearch}) => {
	const [proxySearch, setProxySearch] = useState('');
	const debounceSetSearch = debounce(setSearch, 200);

	return (
		<InputGroup>
			<Form.Control
        placeholder="Search by name or support email"
        aria-label="Search"
        value={proxySearch}
        onChange={e => {
        	setProxySearch(e.target.value);
        	debounceSetSearch(e.target.value);
        }}
      />
		</InputGroup>
	);
};

const TableHeader = ({sort, sortDir, handleSortToggle}) => {
	return (
		<thead>
		  <tr>
				<th scope="col" colname="id" className="hasSort" onClick={handleSortToggle}>
					<span className="mr-2">ID</span>
					<SortIcon direction={sort === 'id' ? sortDir : 'hide'}/>
				</th>
				<th scope="col" colname="dealerDisplayName" className="hasSort" onClick={handleSortToggle}>
					<span className="mr-2">Name</span>
					<SortIcon direction={sort === 'dealerDisplayName' ? sortDir : 'hide'}/>
				</th>
				<th scope="col" colname="supportEmail" className="hasSort" onClick={handleSortToggle}>
					<span className="mr-2">Support Email</span>
					<SortIcon direction={sort === 'supportEmail' ? sortDir : 'hide'}/>
				</th>
		    <th>Dealers</th>
		    <th>Orders</th>
		    <th>Sales</th>
		  </tr>
		</thead>
	);
};

const DealerOrgRow = ({dealerOrg, handleClickDealerOrgRow}) => {
	return (
		<tr onClick={() => handleClickDealerOrgRow(dealerOrg?.id)}>
		  <td>{dealerOrg?.id}</td>
		  <td>{dealerOrg?.dealerDisplayName || dealerOrg?.name}</td>
		  <td>{dealerOrg?.supportEmail}</td>
		  <td>{commaFormat(dealerOrg?.dealersCount, undefined, '0')}</td>
		  <td>{commaFormat(dealerOrg?.orderSummary?.orderCount, undefined, '0')}</td>
		  <td>{currencyFormat(dealerOrg?.orderSummary?.salesTotal, '$0.00')}</td>
		</tr>
	);
};

function DealerOrgsTable(props) {
	const history = useHistory();
	const [search, setSearch] = useState('');
	const [sort, setSort] = useState('id');
	const [sortDir, setSortDir] = useState('ASC');

	const {
		data: dealerOrgs = [],
		isLoading: dealerOrgsIsLoading,
	} = useGetDealerOrgsQuery({search, sort, sortDir});

	function handleSortToggle(e) {
		const col = e.currentTarget.getAttribute('colname');
		const newState = sort !== col ? 'ASC'
			: sortDir === 'DESC' ? 'ASC'
				: 'DESC';

		setSort(col);
		setSortDir(newState);
	};

	const handleClickDealerOrgRow = useCallback((dealerOrgId) => {
		history.push(`/dealerOrgs/${dealerOrgId}`);
	}, [history]);

	return (
		<Section
		  bg={props.bg}
		  textColor={props.textColor}
		  size={props.size}
		  bgImage={props.bgImage}
		  bgImageOpacity={props.bgImageOpacity}
		>
		  <Container>
		  	<SectionHeader
		  	  title={props.title}
		  	  subtitle={props.subtitle}
		  	  size={1}
		  	  spaced={true}
		  	  className="text-center"
		  	/>
		  	<Card className="shadow">
		  		<Card.Header>
		  			<Search setSearch={setSearch} />
		  		</Card.Header>
		  		<Card.Body className="p-0">
		  			{dealerOrgsIsLoading ? (
		  				<Spinner className="py-2 text-muted w-100"/>
		  			) : dealerOrgs.length > 0 ? (
		  				<div>
		  	  			<Table
		  	  				hover responsive
		  	  				className="table-stripe"
		  	  			>
		  	  				<TableHeader
		  	  					sort={sort}
		  	  					sortDir={sortDir}
		  	  					handleSortToggle={handleSortToggle}
		  	  				/>
						      <tbody>
						      	{dealerOrgs.map(dealerOrg => (
						      		<DealerOrgRow
						      			key={dealerOrg.id}
						      			dealerOrg={dealerOrg}
						      			handleClickDealerOrgRow={handleClickDealerOrgRow}
						      		/>
						      	))}
						      </tbody>
						    </Table>
						  </div>
		  			) : (
		  				<tr>
		  					<td colSpan="6" className="text-center">
		  						No Dealer Orgs found
		  					</td>
		  				</tr>
		  			)}
		  		</Card.Body>
		  	</Card>
		  </Container>
		</Section>
	);
};

export default DealerOrgsTable;
