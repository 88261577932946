import { createApi } from '@reduxjs/toolkit/query/react';
import usersHandlers from '../users';
import dealerOrgHandlers from '../dealerOrg';

const baseQueryUri = process.env.NODE_ENV === 'development'
	? "http://localhost:3001"
	: "https://admin-api.atero.co";

// Users
export const usersApiSlice = createApi({
	reducerPath: 'users',
	baseQuery: baseQueryUri,
	endpoints: builder => usersHandlers(builder),
});

export const {
	useGetUsersQuery
} = usersApiSlice;

// Dealer Org
export const dealerOrgApiSlice = createApi({
	reducerPath: 'dealerOrg',
	baseQuery: baseQueryUri,
	endpoints: builder => dealerOrgHandlers(builder),
});

export const {
	useGetDealerOrgsQuery, useGetDealerOrgQuery, useGetDealerOrgDealersQuery, useGetDealerOrgBuyersQuery, useGetDealerOrgBuyerOrgsQuery, useGetDealerOrgManufacturersQuery, useGetDealerOrgOrderSummaryQuery,
} = dealerOrgApiSlice;
