import React, { useState } from "react";
import classnames from 'classnames';
import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import SearchInput from "../SearchInput";
import Section from "../Section";
import SectionHeader from "../SectionHeader";
import SortIcon from "../SortIcon";
import Flex from '../Flex';
import Spinner from "../Spinner";
import Toggle from "../Toggle";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons';
import {
	SetPasswordPopup as SetUserPasswordPopup,
	TableHeader as UsersTableHeader,
	UserRow as UsersTableRow,
} from '../UsersTable/UsersTable';
import useIsMobile from '../../hooks/useIsMobile';
import { useRouter, useHistory } from '../../util/router';
import { currencyFormat, commaFormat } from '../../util/numbers';
import {
	useGetDealerOrgQuery,
	useGetDealerOrgDealersQuery,
	useGetDealerOrgBuyersQuery,
	useGetDealerOrgBuyerOrgsQuery,
	useGetDealerOrgManufacturersQuery,
} from '../../api/client';

const SECTIONS = [
	'dealers',
	'buyer orgs',
	'buyers',
	'manufacturers',
];

const OrgInfoCard = ({isLoading, orderCount, salesTotal, dealersCount, buyerOrgsCount, buyersCount, manufacturersCount}) => {
	return (
		<Card className="shadow mb-3">
			<Card.Header>
				Summary Info
			</Card.Header>
			<Card.Body className="py-2">
				{isLoading ? (
					<Spinner className="py-2 text-muted w-100"/>
				) : (
					<Flex direction="column">
						<Flex justify="between" className="py-1">
							<span>Orders</span>
							<span>{commaFormat(orderCount, undefined, '0')}</span>
						</Flex>
						<Flex justify="between" className="py-1">
							<span>Sales</span>
							<span>{currencyFormat(salesTotal, '$0.00')}</span>
						</Flex>
						<Flex justify="between" className="py-1">
							<span>Dealer Users</span>
							<span>{commaFormat(dealersCount, undefined, '0')}</span>
						</Flex>
						<Flex justify="between" className="py-1">
							<span>Buyer Orgs</span>
							<span>{commaFormat(buyerOrgsCount, undefined, '0')}</span>
						</Flex>
						<Flex justify="between" className="py-1">
							<span>Buyers</span>
							<span>{commaFormat(buyersCount, undefined, '0')}</span>
						</Flex>
						<Flex justify="between" className="py-1">
							<span>Manufacturers</span>
							<span>{commaFormat(manufacturersCount, undefined, '0')}</span>
						</Flex>
					</Flex>
				)}
			</Card.Body>
		</Card>
	);
};

const ToggleCard = ({activeSection, setActiveSection}) => {
	const [isMobile] = useIsMobile();

	return (
		<Toggle
			ops={SECTIONS}
			active={activeSection}
			setActive={setActiveSection}
			className={classnames('mb-3 w-fit', {
				'flex-wrap justify-content-around': isMobile
			})}
		/>
	);
};

const UsersCard = ({title, users, isLoading, search, setSearch, sort, setSort, sortDir, setSortDir}) => {
	const [popupOpen, setPopupOpen] = useState(false);
	const [activeUser, setActiveUser] = useState(false);

	function handleSortToggle(e) {
		const col = e.currentTarget.getAttribute('colname');
		const newState = sort !== col ? 'ASC'
			: sortDir === 'DESC' ? 'ASC'
				: 'DESC';

		setSort(col);
		setSortDir(newState);
	}

	return (
		<>
			<Card className="shadow mb-3">
				<Card.Header>
					<Flex justify="between" align="center">
						<span className="mr-2">{title}</span>
						<div className="flex-grow-1" style={{maxWidth: '80%'}}>
							<SearchInput setSearch={setSearch} />
						</div>
					</Flex>
				</Card.Header>
				<Card.Body className="p-0">
					{isLoading ? (
						<Spinner className="py-2 text-muted w-100"/>
					) : (
						<div>
							<Table
								hover responsive
								className="table-stripe"
							>
								<UsersTableHeader
									sort={sort}
									sortDir={sortDir}
									handleSortToggle={handleSortToggle}
								/>
								<tbody>
									{users.length > 0 ? (
										users.map(user => (
											<UsersTableRow
												key={user.id}
												user={user}
												setActiveUser={setActiveUser}
												setPopupOpen={setPopupOpen}
											/>
										))
									) : (
										<tr>
											<td colSpan="7" className="text-center">
												No {title.toLowerCase()} found
											</td>
										</tr>
									)}
								</tbody>
							</Table>
						</div>
					)}
				</Card.Body>
			</Card>
			<SetUserPasswordPopup
				popupOpen={popupOpen}
				setPopupOpen={setPopupOpen}
				user={activeUser}
				setActiveUser={setActiveUser}
			/>
		</>
	);
};

const BuyerOrgsCard = ({dealerOrgId}) => {
	const [search, setSearch] = useState('');
	const [sort, setSort] = useState('id');
	const [sortDir, setSortDir] = useState('ASC');

	const {
		data: buyerOrgs = [],
		isLoading: buyerOrgsIsLoading,
	} = useGetDealerOrgBuyerOrgsQuery({dealerOrgId, search, sort, sortDir});

	function handleSortToggle(e) {
		const col = e.currentTarget.getAttribute('colname');
		const newState = sort !== col ? 'ASC'
			: sortDir === 'DESC' ? 'ASC'
				: 'DESC';

		setSort(col);
		setSortDir(newState);
	}

	return (
		<Card className="shadow mb-3">
			<Card.Header>
				<Flex justify="between" align="center">
					<span className="mr-2">Buyer Orgs</span>
					<div className="flex-grow-1" style={{maxWidth: '80%'}}>
						<SearchInput setSearch={setSearch} />
					</div>
				</Flex>
			</Card.Header>
			<Card.Body className="p-0">
				{buyerOrgsIsLoading ? (
					<Spinner className="py-2 text-muted w-100"/>
				) : (
					<div>
						<Table
							hover responsive
							className="table-stripe"
						>
							<thead>
							  <tr>
									<th scope="col" colname="id" className="hasSort" onClick={handleSortToggle}>
										<span className="mr-2">ID</span>
										<SortIcon direction={sort === 'id' ? sortDir : 'hide'}/>
									</th>
									<th scope="col" colname="name" className="hasSort" onClick={handleSortToggle}>
										<span className="mr-2">Name</span>
										<SortIcon direction={sort === 'name' ? sortDir : 'hide'}/>
									</th>
								</tr>
							</thead>
							<tbody>
								{buyerOrgs.length > 0 ? (
									buyerOrgs.map(buyerOrg => (
										<tr key={buyerOrg.id}>
										  <td>{buyerOrg?.id}</td>
										  <td>{buyerOrg?.name}</td>
										</tr>
									))
								) : (
									<tr>
										<td colSpan="2" className="text-center">
											No Buyer Orgs found
										</td>
									</tr>
								)}
							</tbody>
						</Table>
					</div>
				)}
			</Card.Body>
		</Card>
	);
};

const DealersCard = ({dealerOrgId}) => {
	const [search, setSearch] = useState('');
	const [sort, setSort] = useState('id');
	const [sortDir, setSortDir] = useState('ASC');

	const {
		data: dealers = [],
		isLoading: dealersIsLoading,
	} = useGetDealerOrgDealersQuery({dealerOrgId, search, sort, sortDir});

	return (
		<UsersCard
			title="Dealers"
			users={dealers}
			isLoading={dealersIsLoading}
			search={search}
			setSearch={setSearch}
			sort={sort}
			setSort={setSort}
			sortDir={sortDir}
			setSortDir={setSortDir}
		/>
	);
};

const BuyersCard = ({dealerOrgId}) => {
	const [search, setSearch] = useState('');
	const [sort, setSort] = useState('id');
	const [sortDir, setSortDir] = useState('ASC');

	const {
		data: buyers = [],
		isLoading: buyersIsLoading,
	} = useGetDealerOrgBuyersQuery({dealerOrgId, search, sort, sortDir});

	return (
		<UsersCard
			title="Buyers"
			users={buyers}
			isLoading={buyersIsLoading}
			search={search}
			setSearch={setSearch}
			sort={sort}
			setSort={setSort}
			sortDir={sortDir}
			setSortDir={setSortDir}
		/>
	);
};

const ManufacturersCard = ({dealerOrgId}) => {
	const [search, setSearch] = useState('');
	const [sort, setSort] = useState('id');
	const [sortDir, setSortDir] = useState('ASC');

	const {
		data: manufacturers = [],
		isLoading: manufacturersIsLoading,
	} = useGetDealerOrgManufacturersQuery({dealerOrgId, search, sort, sortDir});

	function handleSortToggle(e) {
		const col = e.currentTarget.getAttribute('colname');
		const newState = sort !== col ? 'ASC'
			: sortDir === 'DESC' ? 'ASC'
				: 'DESC';

		setSort(col);
		setSortDir(newState);
	}

	return (
		<Card className="shadow mb-3">
			<Card.Header>
				<Flex justify="between" align="center">
					<span className="mr-2">Manufacturers</span>
					<div className="flex-grow-1" style={{maxWidth: '80%'}}>
						<SearchInput setSearch={setSearch} />
					</div>
				</Flex>
			</Card.Header>
			<Card.Body className="p-0">
				{manufacturersIsLoading ? (
					<Spinner className="py-2 text-muted w-100"/>
				) : (
					<div>
						<Table
							hover responsive
							className="table-stripe"
						>
							<thead>
							  <tr>
									<th scope="col" colname="id" className="hasSort" onClick={handleSortToggle}>
										<span className="mr-2">ID</span>
										<SortIcon direction={sort === 'id' ? sortDir : 'hide'}/>
									</th>
									<th scope="col" colname="name" className="hasSort" onClick={handleSortToggle}>
										<span className="mr-2">Name</span>
										<SortIcon direction={sort === 'name' ? sortDir : 'hide'}/>
									</th>
								</tr>
							</thead>
							<tbody>
								{manufacturers.length > 0 ? (
									manufacturers.map(manufacturer => (
										<tr key={manufacturer.id}>
										  <td>{manufacturer?.id}</td>
										  <td>{manufacturer?.name}</td>
										</tr>
									))
								) : (
									<tr>
										<td colSpan="2" className="text-center">
											No manufacturers found
										</td>
									</tr>
								)}
							</tbody>
						</Table>
					</div>
				)}
			</Card.Body>
		</Card>
	);
};

function DealerOrg(props) {
	const router = useRouter();
	const history = useHistory();
	const { dealerOrgId } = router?.params;
	const [activeSection, setActiveSection] = useState(SECTIONS[0]);

	const {
		data: dealerOrg = {},
		isLoading: dealerOrgIsLoading,
	} = useGetDealerOrgQuery(dealerOrgId);

	return (
		<Section
		  bg={props.bg}
		  textColor={props.textColor}
		  size={props.size}
		  bgImage={props.bgImage}
		  bgImageOpacity={props.bgImageOpacity}
		>
		  <Container fluid="lg">
		  	<SectionHeader
		  	  title={dealerOrg?.dealerDisplayName || dealerOrg?.name || <Spinner className="text-muted"/>}
		  	  subtitle={props.subtitle}
		  	  size={1}
		  	  spaced={true}
		  	  className="text-center"
		  	/>
		  	{dealerOrgIsLoading ? (
		  		<Spinner className="py-2 text-muted w-100"/>
		  	) : (
			  	<Row>
			  		<Col xs={12}>
			  			<Flex
			  				align="center"
			  				className="text-gray-700 pointer"
			  				style={{marginTop: '-2rem', marginBottom: '.5rem'}}
			  				onClick={() => history.push('/dealerOrgs')}
			  			>
			  				<FontAwesomeIcon icon={faChevronLeft} style={{fontSize: '.85rem'}}/>
			  				<span className="ml-2">Back</span>
			  			</Flex>
			  		</Col>
			  		<Col md={12} lg={4} xl={3}>
			  			<OrgInfoCard
			  				isLoading={dealerOrgIsLoading}
			  				orderCount={dealerOrg?.orderCount}
			  				salesTotal={dealerOrg?.salesTotal}
			  				dealersCount={dealerOrg?.dealersCount}
			  				buyerOrgsCount={dealerOrg?.buyerOrgsCount}
			  				buyersCount={dealerOrg?.buyersCount}
			  				manufacturersCount={dealerOrg?.manufacturersCount}
			  			/>
			  		</Col>
			  		<Col md={12} lg={8} xl={9}>
			  			<ToggleCard
			  				activeSection={activeSection}
			  				setActiveSection={setActiveSection}
			  			/>
			  			<div className={classnames({
		  					'd-none': activeSection !== 'dealers'
			  			})}>
			  				<DealersCard dealerOrgId={dealerOrgId} />
			  			</div>
			  			<div className={classnames({
		  					'd-none': activeSection !== 'buyer orgs'
			  			})}>
			  				<BuyerOrgsCard dealerOrgId={dealerOrgId} />
			  			</div>
			  			<div className={classnames({
		  					'd-none': activeSection !== 'buyers'
			  			})}>
			  				<BuyersCard dealerOrgId={dealerOrgId} />
			  			</div>
			  			<div className={classnames({
		  					'd-none': activeSection !== 'manufacturers'
			  			})}>
			  				<ManufacturersCard dealerOrgId={dealerOrgId} />
			  			</div>
			  		</Col>
			  	</Row>
		  	)}
		  </Container>
		</Section>
	);
};

export default DealerOrg;
